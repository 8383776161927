/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.NotFoundView {
  padding: 1em;
}
